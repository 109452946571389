.horizontalbar{
    padding: 20px 25px;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #FFFFFF;
}


.horizontalbar h2 {
    text-align: center;
    margin-bottom: 20px;
}

.card-deck {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card {
    width: 200px; /* Adjust this value to make the card smaller */

    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card:hover {
    cursor: pointer;
    transition: all
}

.card img {
    width: 100%;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card-body {
    padding: 10px;
}

.card-title {
    font-size: 16px; /* Adjust this value for title size */
    margin-bottom: 8px;
}

.card-text {
    font-size: 12px; /* Adjust this value for text size */
    color: #666;
}

.card-text small {
    font-size: 10px; /* Adjust this value for small text */
}
