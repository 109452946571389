.main_header {
    display: flex;
    height: 70px;
    /* border-bottom: 1px solid rgb(219, 219, 219); */
    width: 100vw;
}

.header__logo {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* width: 270px; */
}

.header__logo>img {
    width: 270px;
    height: 98px;
    cursor: pointer;
}

.MuiSvgIcon-root {
    color: rgb(82, 82, 82);
}

.header__icons {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    padding: 10px;
}

.header__icons .MuiSvgIcon-root {
    font-size: 25px;
    color: rgb(82, 82, 82);
    margin: 10px;
}

.header__icons>span{
    margin-right: 5px;
}

.header__icons>img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 10px;
}

.header__logo__dropdown {
    z-index: 1000;
    position: fixed;
    background-color: #f9f9f9;
    min-width: 140px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    top: 0;
    right: 0;
    margin-top: 60px;
    margin-right: 15px;
    border-radius: 10px;
    border: 1px solid rgb(219, 219, 219);
}

.header__logo__dropdown>a {
    color: rgb(54, 49, 49);
    padding: 10px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.header__logo__dropdown>a:hover {
    background-color: #ddd;
}