.fileItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    border-bottom: 1px solid rgb(219, 219, 219);
    width: 100%;
    text-decoration: none;
    color: rgb(85, 78, 78);
    padding: 12px 0;
    position: relative;
    user-select: none;
}

.fileItem>div>*{
    margin: 15px;
}

.fileItem--left{
    font-weight: 550;
    display: flex;
    width: 450px;
    cursor: pointer;
}

.fileItem--right{
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileItem--date {
    width: 150px;
}

.fileItem--size {
    width: 100px;
}

.fileItem:hover{
    background-color: rgba(0, 0, 0, 0.075);
}

.fileItem.selected {
    background-color: #C2E7FF; /* Change the background color as needed */
  }

.fileItem--more-wrapper {
    width: 35px; 
    height: 35px; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease; /* Add transition for smooth hover effect */
}

.fileItem--more-wrapper:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Change background color on hover */
}

.fileAction__dropdown {
    z-index: 1000;
    position: fixed;
    background-color: #f9f9f9;
    min-width: 140px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* top: 0;
    right: 0;
    margin-top: 4em;
    margin-right: 5em; */
    border-radius: 10px;
    border: 1px solid rgb(219, 219, 219);
}

.fileAction__dropdown>a {
    color: rgb(54, 49, 49);
    padding: 10px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.fileAction__dropdown>a:hover {
    background-color: #ddd;
}