.fileView{
    position: relative;
    width: 100%;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: #FFFFFF;
}

.fileView__titles{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    border-bottom: 1px solid rgb(219, 219, 219);
    width: 100%;
    color: rgb(17, 16, 16);
    font-weight: 800;
    user-select: none;
}

.fileView__titles>div>*{
    margin: 15px;
}

.fileView__content {
    overflow-y: auto;
    height: 50vh;
}

.fileView__placeholder {
    display: flex;
    justify-content: center;
    height: 50vh;;
    color: #555;
    text-align: center;
    flex-direction: column;
    font-size: 18px;
  }
  
  .first-paragraph {
    color: black;
    font-size: 20px;
  }