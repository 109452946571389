.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    background-color: #F6F8FC;
  }
  
  .app__main {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  .sidebar {
    flex: 0 0 250px; /* Adjust the width as needed */
    overflow-y: auto;
  }
  
  .main__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .horizontalbar {
    flex-shrink: 0; /* Ensure it doesn't shrink */
  }
  
  .filesView {
    flex: 1;
    overflow-y: auto;
  }
  
  .app__login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
  }
  

.app__login>button{
    position: absolute;
    z-index: 1;
    border: none;
    font-size: 24px;    
    background-color: rgb(243, 90, 43);
    color: white;
    padding: 10px 20px;
    border-radius: 6px;
    transition: all 0.2s;
    top: 78%;
    left: 40%;
}

.app__login>p{
    font-size: 14px;
    position: absolute;
    z-index: 1;
    top: 87%;
    left: 37%;
}

.app__login>button:hover{
    cursor: pointer;
    background-color: rgb(243, 90, 43);
    transform: scale(1.1);
}

.app__login form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  };
.app__login input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  };
