.sidebar{
    width: 15%;
    height: 100vh;
    margin-right: 5px;
}

hr{
    background-color: rgb(197, 197, 197);
    height: 1px;
    border: none;
}

.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }