.sidebarItem{
    width: 180px;
    display: flex;
    padding: 6px 12px;
    /* TRouBLe */
    border-radius: 100px 100px 100px 100px;
    margin-left: 18px;
    margin-right: 18px;
    background-color: #C2E7FF;
    /* background-color: white; */
    color: rgb(85, 78, 78);
    font-weight: 650;
}

.sidebarItem:hover{
    background-color: #C2E7FF;
    cursor: pointer;
}

.sidebarItem__main{
    display: flex;
}

.sidebarItem__main>p{
    margin-left: 14px;
}